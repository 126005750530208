<template>
  <div class="container-fluid mt-5">
    <div class="user-edit">
      <div slot="header" class="user-edit-header">
        <h3 class="mb-0">{{ $t("USERS.EDIT_USER") }}</h3>
        <base-button @click="goBack" type="button" class="btn btn-sm">
          <i class="fal fa-arrow-left"></i>
          {{ $t("COMMON.GO_BACK") }}
        </base-button>
      </div>
      <user-form
        :loading="loading"
        :userData="user"
        :formErrors="formErrors"
        :showIsStaff="false"
        :showOrganization="false"
        :showReseller="false"
        @userSubmitted="handleSubmit"
        @formChanged="() => (alertLeave = true)"
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import UserForm from "@/views/Pages/AdminModule/UserManagement/partials/UserForm.vue";
import defaultUser from "@/views/Pages/AdminModule/UserManagement/defaultUser";

export default {
  layout: "DashboardLayout",

  components: { UserForm },

  mixins: [alertLeave],

  data() {
    return {
      user: cloneDeep(defaultUser),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("profile/me");
        this.user = { ...this.user, ...this.$store.getters["profile/me"] };
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "View Profile" });
    },

    async handleSubmit(user) {
      this.loading = true;

      const userData = cloneDeep(user);
      if (userData.roles[0]) {
        if (!userData.roles[0].id) {
          delete userData.roles[0].id;
        }
      }
      if (userData.organization) {
        if (!userData.organization.id) {
          delete userData.organization;
        }
      }
      if (userData.reseller) {
        if (!userData.reseller.id) {
          delete userData.reseller;
        }
      }
      if (!userData.password) {
        delete userData.password;
        delete userData.password_confirmation;
      }

      try {
        await this.$store.dispatch("profile/update", userData);
        this.$notify({
          type: "success",
          message: this.$t("PROFILE.PROFILE_UPDATED"),
        });
        this.viewProfile();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    viewProfile() {
      this.alertLeave = false;
      this.$router.push({
        name: "View Profile",
      });
    },
  },
};
</script>
